import { makeStyles } from '@material-ui/core'
import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'



const useStyles = makeStyles(() => ({
    handleContainer: {
        "--rcs-handle-width": '2px',
        "--rcs-handle-box": '1.5rem',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2px',
        height: '100%',
        boxShadow: 'none',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
    },
    circle:{
        position: 'absolute',
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            gridAutoFlow: 'column',
            width: '1.5rem',
            height: '1.5rem',
            border: '2px solid #ffffff',
            gap: '0.65rem',
            backdropFilter: 'blur(0.5rem)',
            borderRadius: '100%',
            overflow: 'hidden',
            '&::before': {
              content: '',
              display: 'block',
              width: 0,
              height: 0,
              top: '50%',
              left: 0,
              borderTop: '0.5rem solid transparent',
              borderBottom: '0.5rem solid transparent',
              borderRight: '0.65rem solid #ffffff',
            },
            '&::after':{
              borderLeft: '0.65rem solid #ffffff',
              content: '',
              display: 'block',
              width: 0,
              height: 0,
              top: '50%',
              left: 0,
              borderTop: '0.5rem solid transparent',
              borderBottom: '0.5rem solid transparent',
            }
    },
    arrows: {
        position: 'absolute',
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '100%',
            overflow: 'hidden',
            '&::before,&::after': {
              content: '',
              position: 'absolute',
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(0.5rem)',
            }
    }
}))

const ComparisonSlider = (before: any, after: any) => {

    const classes = useStyles()
    return (
        <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={before.before} alt="before" />}
            itemTwo={<ReactCompareSliderImage src={before.after} alt="after" />}
            handle={
                <div
                    className={classes.handleContainer}
                >
                    <div className={classes.arrows}/>
                    <div className={classes.circle}/>
                </div>
            }
        />
    )
}

export default ComparisonSlider