import { Avatar, Grid, makeStyles, Typography, Container } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EmbededVideo from '../../shared/EmbededVideo'
import CountUp from '../../shared/UpCounter'
import React from 'react'

import jordan_banner from '../../../assets/images/case_studies/case_02/banner_jordan.mp4'
import gif1 from '../../../assets/images/case_studies/case_02/GIFS/jordan_01.gif'
import gif2 from '../../../assets/images/case_studies/case_02/GIFS/jordan_02.gif'

const useStyles = makeStyles((theme) => ({
    gradientUnderline: {
        borderBottom: "3px solid",
        paddingBottom: '0.25rem',
        borderImage: "linear-gradient(90deg, #531EED 0%, #2DCCFE 45.31%, #FF85FF 100%) 10",
        borderTop: '0px',
        borderLeft: '0px',
        borderRight: '0px'
    },
    avatar: {
        height: '3rem',
        width: '3rem',
        margin: '0 0.5rem',
        color: 'white'
    },
    counterGridItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    playIcon: {
        fontSize:theme.typography.h1.fontSize,
    }

}))

const Case_02 = () => {
    const classes = useStyles();
    const URL1 = 'https://player.vimeo.com/video/487048187?title=0&byline=0&portrait=0'

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container disableGutters={false} maxWidth='lg'>
            <Grid container direction='column'>
                <Grid container direction='row' justify='center'>
                    <video loop autoPlay={true} style={{ width: '100%', height: 'auto', maxHeight: '100%' }} muted>
                        <source src={jordan_banner} type="video/mp4" />
                    </video>
                </Grid>
                <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                    <div className={classes.gradientUnderline} style={{ width: '13rem' }}>

                    </div>
                </Grid>
                <Grid container direction='row' justify='center' style={{ padding: '0 10rem' }}>


                    <Typography variant='body1' align='justify'>
                        Nike–Jordan Brand contracted us to create a teaser video for their upcoming activation. The activation was held at the only Jordan Store in Canada, “306 Yonge”.
                        It showcases Toronto artists’ unique take on Jordan Brand’s new high-end clothing line--the 23 Engineered Apparel Collection.
                    </Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{ marginTop: '1rem', marginBottom: "4rem" }}>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#501EE9' }}>V</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#2DCBFE' }}>P</Avatar>
                   
                </Grid>
                <Grid container direction='row' justify='center'>
                    <div style={{ width: '100%', height: '30rem', }}>
                        <EmbededVideo sourceURL={URL1} />
                    </div>
                </Grid>

                <Grid container direction='row' justify='center' style={{ marginTop: '2rem', marginBottom: "0rem", padding: '0 10rem' }}>
                    <Typography variant='body1' align='justify'>
                        We visually translated the artists personalities and their styling process. Given the urgent half-day turnaround, it was imperative that the project was delivered on time.
                    </Typography>
                </Grid>
                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ marginTop: '6rem' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ paddingTop: '4rem' }}>
                            <Typography variant='h2'>Results</Typography>
                        </Grid>
                        <Grid container direction='row' justify='center' style={{ margin: '4rem 0' }}>
                            <Typography variant='body1'>
                                Once submitted, the client expressed how satisfied they were with the final product. The client shared the video with Foot Locker’s Footaction stores to collectively promote the consumer-facing event.
                            </Typography>
                            <Typography variant='body1'>
                                The video has 30,000 unique views across platforms, creating brand awareness and converting views to foot traffic at the physical storefront.
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center' spacing={1} style={{ margin: 0, width: '100%' }}>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem', }}>
                            <img src={gif1} alt='gif1' style={{ maxHeight: '100%', maxWidth: '100%', width: '100%' }} />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem', }}>
                            <img src={gif2} alt='gif1' style={{ maxHeight: '100%', maxWidth: '100%', width: '100%' }} />
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ marginTop: '0' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ paddingTop: '4rem' }}>
                            <Typography variant='h3'>Total Views</Typography>
                        </Grid>
                        <Grid container direction='row' justify='space-between' style={{marginTop:'2rem'}}>
                            <Grid item md={2} className={classes.counterGridItem} style={{alignContent:'left'}}>
                                <PlayCircleFilledIcon className={classes.playIcon}/>
                            </Grid>
                            <Grid item md={6} className={classes.counterGridItem} style={{alignContent:'right'}}>
                                <CountUp value={30000} textVariant='h1'/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>





                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ margin: '2.5rem 0' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Case_02;