import { Avatar, Grid, makeStyles, Typography, Container } from '@material-ui/core';
import EmbededVideo from '../../shared/EmbededVideo'
import React from 'react'
import poc_banner from "../../../assets/images/case_studies/case_01/poc_banner.mp4"
import ComparisonSlider from '../../shared/ComparisonSlider'

import before1 from '../../../assets/images/case_studies/case_01/before_after_slider/girl_before.jpg'
import after1 from '../../../assets/images/case_studies/case_01/before_after_slider/girl_after.jpg'

import before2 from '../../../assets/images/case_studies/case_01/before_after_slider/room_before.png'
import after2 from '../../../assets/images/case_studies/case_01/before_after_slider/room_after.jpg'

import before3 from '../../../assets/images/case_studies/case_01/before_after_slider/skull_before.jpg'
import after3 from '../../../assets/images/case_studies/case_01/before_after_slider/skull_after.jpg'

import gif1 from '../../../assets/images/case_studies/case_01/GIFS/01.gif'
import gif2 from '../../../assets/images/case_studies/case_01/GIFS/02.gif'
import gif3 from '../../../assets/images/case_studies/case_01/GIFS/03.gif'
import gif4 from '../../../assets/images/case_studies/case_01/GIFS/04.gif'

const useStyles = makeStyles(() => ({
    gradientUnderline: {
        borderBottom: "3px solid",
        paddingBottom: '0.25rem',
        borderImage: "linear-gradient(90deg, #531EED 0%, #2DCCFE 45.31%, #FF85FF 100%) 10",
        borderTop: '0px',
        borderLeft: '0px',
        borderRight: '0px'
    },
    avatar: {
        height: '3rem',
        width: '3rem',
        margin: '0 0.5rem',
        color: 'white'
    },

   

}))

const Case_01 = () => {
    const classes = useStyles();
    const URL1 = 'https://player.vimeo.com/video/476665356?title=0&byline=0&portrait=0'
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    
    return (
        <Container disableGutters={false} maxWidth='lg'>
            <Grid container direction='column'>
                <Grid container direction='row' justify='center'>
                        <video loop autoPlay={true} style={{width:'100%', height:'auto', maxHeight:'100%'}} muted>
                            <source src={poc_banner} type="video/mp4" />
                        </video>
                </Grid>
                <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                    <div className={classes.gradientUnderline} style={{ width: '13rem' }}>

                    </div>
                </Grid>
                <Grid container direction='row' justify='center' style={{ padding: '0 10rem' }}>

                    <Typography variant='body1' align='justify'>
                    Devour You is an experimental art piece. This piece showcases our integrated approach to visual effects, 
                    3D motion design and filmmaking. Putting emphasis on emotions and creating the “wow-factor”, we are able to grab viewers' attention.
                    </Typography>

                </Grid>
                <Grid container direction='row' justify='center' style={{ marginTop: '1rem', marginBottom: "4rem" }}>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#501EE9' }}>V</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#2DCBFE' }}>P</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#FE2D2D' }}>3D</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#FEAA2D' }}>VFX</Avatar>
                </Grid>
                <Grid container direction='row' justify='center'>
                    <div style={{ width: '100%', height: '30rem'}}>
                        <EmbededVideo sourceURL= {URL1}/>
                    </div>
                </Grid>
                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ marginTop: '6rem' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ paddingTop: '4rem', paddingBottom: '2rem' }}>
                            <Typography variant='h2'>Breakdown</Typography>
                        </Grid>
                        <Grid container direction='row' justify='center'>
                            <div style={{ width: '100%', height: '28rem' }}>
                                <ComparisonSlider before={before1} after={after1}/>
                            </div>

                            <div style={{ width: '100%', height: '28rem' }}>
                                <ComparisonSlider before={before2} after={after2}/>
                            </div>

                            <div style={{ width: '100%', height: '28rem' }}>
                                <ComparisonSlider before={after3} after={before3}/>
                            </div>
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
                            <Typography variant='h2'>
                                VFX
                    </Typography>
                        </Grid>
                        {/* <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                            <Typography variant='body1'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci dignissim vestibulum velit molestie amet. Suspendisse urna malesuada sollicitudin purus nunc mauris.
                    </Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center' spacing={1} style={{ margin: 0, width: '100%' }}>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif1} alt='gif1' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif2} alt='gif2' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem', }}>
                            <img src={gif3} alt='gif3' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif4} alt='gif4' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center' style={{ paddingTop: '4rem' }}>
                            <Typography variant='h2' align = 'center' >Credits</Typography>
                        </Grid>
                        <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                            <Typography variant='body1' >
                            <ul style = {{ listStyleType: 'none', textAlign: 'center', padding:'0'}}>
                            <li>Directed & Edited by: @maxmmh2.0</li>
                            <li>Producer & BTS: @fvstlane</li>
                            <li>Model: @rowanburatti</li>
                            <li>Makeup: @finessebyashlyn</li>
                            <li>Studio: @mrj1studios</li>
                            <li>Soundtrack: @brvmes-Rêve (non-affiliated)</li>
                            </ul>
                            </Typography>  
                            
                        </Grid>
                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 3rem' }}>
                        <Grid container direction='row' style={{ margin: '2.5rem 0' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='center'style= {{ paddingBottom: '4rem'}}>
                            <Typography variant='body1'>Done on: CINEMA 4D /  Octane render / TFD / Adobe After Effects</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Case_01;