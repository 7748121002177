import React from "react"
import Iframe from 'react-iframe'

const EmbededVideo = (sourceURL:any) => {
    console.log(sourceURL.sourceURL)
  return (
    <Iframe url= {sourceURL.sourceURL} 
    width="100%"
    height="100%"
    className="embededVideo"
    display="inline"
    position="relative"
    frameBorder={0}
    allowFullScreen
    />
  )
}

export default EmbededVideo
