import { Input, makeStyles } from '@material-ui/core';
import React, { ChangeEvent } from 'react';


const useStyles = makeStyles((theme) => ({
    primaryBase: {
        border: `2px solid ${theme.palette.primary.main}`,
        padding: '1.25rem 0.75rem',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'black'
    },
    secondaryBase: {
        border: `2px solid ${theme.palette.secondary.main}`,
        padding: '1.25rem 0.75rem',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'white',
    },
    multiline: {
        padding: 0,
    }
}))

type ComponentProps = {
    placeholder?: string,
    type?: string,
    multiline?: boolean,
    color?: "primary" | "secondary" | undefined,
    onChange?: Function,
    value?: string | undefined,
}

const MinimalTextField = ({placeholder, type, multiline, color, onChange, value}: ComponentProps) => {
    const classes = useStyles();
    let inputClass;
    
    if(color === 'primary'){
        inputClass = classes.primaryBase
    } else {
        inputClass = classes.secondaryBase
    }
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(onChange) {
            onChange(e);
        }
    }

    return (
        <Input
            color={color? color : 'secondary'}
            value={value}
            fullWidth={true}
            placeholder={placeholder}
            type={type? type : 'text'}
            multiline={multiline? multiline : false}
            onChange={handleOnChange}
            classes={{
                input: inputClass,
                multiline: classes.multiline,
            }} />

    )
}

export default MinimalTextField