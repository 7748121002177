import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Color } from '@material-ui/lab';

export const Info = (props: any) => {
    const [state, setState] = useState(false)
    const [text, setText] = useState("")
    const [title, setTitle] = useState("")
    const [type, setType] = useState<"success" | "info" | "warning" | "error" | undefined>(undefined)

    useEffect(() => {
        setState(props.state);
        setText(props.text)
        if (props.text === 'emptyField') {
            setText("Please fill all fields")
            setTitle("Error")
            setType("error")
        }
        if (props.text === 'invalidEmail') {
            setText("Please Enter a valid email")
            setTitle("Error")
            setType("error")
        }
        if (props.text === 'success') {
            setText("We have received your request")
            setTitle("Thank you!")
            setType("success")
        }
    }, [props]);

    return (
        <div>
            {
                state &&
                <Alert variant="outlined" severity={type} style={{marginBottom: '1em', color: 'white'}}>
                    <AlertTitle>{title}</AlertTitle>
                    <strong>{text}</strong>
                </Alert>
            }
        </div>
    )
}