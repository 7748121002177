import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CountUp, { useCountUp } from 'react-countup'
import './UpCounter.css'
import VisibilitySensor from 'react-visibility-sensor';


type ComponentProps = {
    value: number,
    duration?: number,
    textVariant?: "body1" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body2" | "overline" | "srOnly" | undefined,
}

export default function UpCounter({ value, duration = 7, textVariant = 'body1' }: ComponentProps) {

    const [didShow, setDidShow] = useState(false);
    const { countUp, start } = useCountUp({
        start: 0,
        end: value,
        separator:',',
        duration: duration,
        startOnMount: false,

    });
    const onVisibilityChange = (isVisible: boolean) => {
        if(isVisible && !didShow){
            start()
            setDidShow(true)
        }
    }

    return (
        <VisibilitySensor onChange={onVisibilityChange} delayedCall>
            <Typography variant={textVariant} align='right' display='inline'>{countUp}</Typography>
        </VisibilitySensor>

    )
}