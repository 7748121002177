import { Avatar, Grid, makeStyles, Typography, Container } from '@material-ui/core';
import EmbededVideo from '../../shared/EmbededVideo'
import ComparisonSlider from '../../shared/ComparisonSlider'
import React from 'react'
import banner from '../../../assets/images/case_studies/case_03/banner.mp4'

import gif1 from '../../../assets/images/case_studies/case_03/GIFS/before1.gif'
import gif2 from '../../../assets/images/case_studies/case_03/GIFS/after1.gif'

import gif3 from '../../../assets/images/case_studies/case_03/GIFS/before2.gif'
import gif4 from '../../../assets/images/case_studies/case_03/GIFS/after2.gif'

import sketch from '../../../assets/images/case_studies/case_03/sliderContent/sketch.gif'
import progress from '../../../assets/images/case_studies/case_03/sliderContent/progress.gif'
import final from '../../../assets/images/case_studies/case_03/sliderContent/final.gif'
import UpCounter from '../../shared/UpCounter';

const useStyles = makeStyles(() => ({
    gradientUnderline: {
        borderBottom: "3px solid",
        paddingBottom: '0.25rem',
        borderImage: "linear-gradient(90deg, #531EED 0%, #2DCCFE 45.31%, #FF85FF 100%) 10",
        borderTop: '0px',
        borderLeft: '0px',
        borderRight: '0px'
    },
    avatar: {
        height: '3rem',
        width: '3rem',
        margin: '0 0.5rem',
        color: 'white'
    },
   

}))

const Case_03 = () => {
    const classes = useStyles();
    const URL1 = 'https://www.youtube.com/embed/TfUb-AfBPn4'

    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    

    return (
        <Container disableGutters={false} maxWidth='lg'>
            <Grid container direction='column'>
                <Grid container direction='row' justify='center'>
                        <video loop autoPlay={true} style={{width:'100%', height:'auto', maxHeight:'100%'}} muted>
                            <source src={banner} type="video/mp4" />
                        </video>
                </Grid>
                <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                    <div className={classes.gradientUnderline} style={{ width: '13rem' }}>

                    </div>
                </Grid>
                <Grid container direction='row' justify='center' style={{ padding: '0 10rem' }}>
                    <Typography variant='body1' align='justify'>
                        Being one of the most influential dance crews in the world, Kinjaz’s visuals have to innovate constantly. Here is where we came in.
                    </Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{ marginTop: '1rem', marginBottom: "4rem" }}>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#501EE9' }}>V</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#FE2D2D' }}>3D</Avatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#FEAA2D' }}>VFX</Avatar>
                </Grid>
                <Grid container direction='row' justify='center'>
                    <div style={{ width: '100%', height: '30rem'}}>
                        <EmbededVideo sourceURL= {URL1}/>
                    </div>
                </Grid>
                <Grid container direction='row' justify='center' style={{ marginTop: '2rem', marginBottom: "0rem", padding: '0 10rem' }}>
                    <Typography variant='body1' align='justify'>
                        We worked closely with Kinjaz’s marketing team, Vibrancy, in creating an innovative choreo music video. Directed by Jonathan Shih.
                    </Typography>
                </Grid>
                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ marginTop: '6rem' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ paddingTop: '4rem' }}>
                            <Typography variant='h2'>Breakdown</Typography>
                        </Grid>
                        <Grid container direction='row' justify='center' style={{ margin: '4rem 0' }}>
                            <Typography variant='body1'>
                                This project was particularly challenging because 3D visual effects were new to the global dance community. 
                                We had to find the balance between innovation and the current norms.
                            </Typography>
                        </Grid>
                        <Grid container direction='row' justify='center'>
                            <div style={{ width: '100%', height: '30rem' }}>
                                <ComparisonSlider before={sketch} after={progress}/>
                            </div>

                            <div style={{ width: '100%', height: '30rem' }}>
                                <ComparisonSlider before={progress} after={final}/>
                            </div>
                        </Grid>
                        <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }}>

                            </div>
                        </Grid>
                        <Grid container direction='row' justify='flex-start' style={{ marginTop: '2rem' }}>
                            <Typography variant='h2'>
                                Results
                            </Typography>
                        </Grid>
                        <Grid container direction='row' justify='center' style={{ margin: '2rem 0' }}>
                            <Typography variant ='h3'>
                            <ul style = {{ listStyleType: 'none', textAlign: 'justify', padding:'0'}}>
                            <li>Instagram: <UpCounter value={193835} textVariant='h3'/> Views </li>
                            <li>YouTube: <UpCounter value={164812} textVariant='h3'/> Views </li>
                            <li>Total Views: <UpCounter value={358647} textVariant='h3'/> Views</li>
                            </ul>
                            </Typography>

                            <Typography variant='body1'>
                                Two months in, The Scotts gained 358,647 total views across Instagram and Youtube. 
                                The viral factors include the shareability and positive feedback of the visual production, leading to reaction videos from the dance community. 
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='center' spacing={1} style={{ margin: 0, width: '100%' }}>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif1} alt='gif1' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif2} alt='gif2' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem', }}>
                            <img src={gif3} alt='gif3' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ width: '100%', height: '20rem'}}>
                            <img src={gif4} alt='gif4' style={{maxHeight:'100%', maxWidth:'100%', width:'100%'}}/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction='row'>
                    <Grid container direction='column' style={{ padding: '0 8rem' }}>
                        <Grid container direction='row' style={{ margin: '3.5rem 0' }}>
                            <div className={classes.gradientUnderline} style={{ width: '100%' }} />
                        </Grid>
                        <Grid container direction='row' justify='center' style={{paddingBottom: '4rem'}}>
                            <Typography variant='body1'>Done on: CINEMA 4D /  Octane render / TFD / Adobe After Effects</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Case_03;