import { Button, Grid, makeStyles, Typography, ButtonBase } from '@material-ui/core';
import logo from '../../assets/images/footer_images/flw_footer.png'
import linkedin from '../../assets/images/footer_images/linkedin.svg'
import Be from '../../assets/images/footer_images/Be.svg'
import ig from '../../assets/images/footer_images/instagram.svg'
import fb from '../../assets/images/footer_images/facebook.svg'
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NONAME } from 'dns';


const useStyles = makeStyles((theme) => ({

    main: {
        marginBottom: '3rem',
        borderTop: '3px solid',
        paddingTop: '2rem'
    },

    list: {

        borderRight: '3px solid'
    },

    link: {
        color: 'white'
    },

    linkText: {
        lineHeight: '2rem',
        fontSize: '1rem'
    },

    social: {
        lineHeight: '2rem',
        fontSize: '1rem',
        paddingLeft: '0.5rem'
        
    },

    socialIcons: {
        display: 'flex',
        paddingTop: '1rem'
    },

    icon: {
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem'
    },

    careerButton: {
        backgroundColor: 'white',
    },

    buttonText: {
        fontSize: '.6rem',
        color: 'black',
        textTransform: 'lowercase'
    },
    
    sideLine: {
        borderLeft: '3px solid white',
        paddingLeft: '2rem'
    },
   
    poweredbyText: {
        fontSize: '0.8rem',
        color: 'white',
        paddingTop: '2.5rem',
        fontWeight: 400,
        textDecoration: 'none'

    }
}))

export default function Footer() {

    const classes = useStyles()
    const history = useHistory()

    const goToWork = () => {
        history.push('/work')
    }

    const goToAbout = () => {
        history.push('/about')
    }

    const goToContact = () => {
        history.push('/contact')
    }

    const handleGoSite = () => {
        window.location.href = 'http://www.digitaldashdev.com'
    }

    return (
        <Grid container direction='row' className={classes.main} >
            <Grid item md={3}>
                <Grid container direction='column' justify='center' style={{ height: '100%' }}>
                    <a href='/'>
                        <img src={logo} alt='' style={{height: 'auto', width:'100%', maxWidth: '7rem', paddingLeft: '1rem'}} />
                    </a>
                </Grid>
            </Grid>
            <Grid item md={6}>
                <Grid container direction='row'>
                    <Grid item md={6} style={{paddingLeft:'2rem'}}>
                        <ul style={{ listStyleType: 'none' }}>
                            <li><ButtonBase onClick={(e) => {goToWork()}}>
                                    <Typography variant='h4' className={classes.linkText}>work</Typography>
                            </ButtonBase></li>

                         <li><ButtonBase onClick={(e) => {goToAbout()}}>
                                <Typography variant='h4' className={classes.linkText}>about</Typography>
                            </ButtonBase></li>
                            
                            <li><ButtonBase onClick={(e) => {goToContact()}}>
                                    <Typography variant='h4' className={classes.linkText}>contact</Typography>
                            </ButtonBase></li>
                        </ul>
                    </Grid>
                    <Grid item md={6} className={classes.sideLine}>
                        <Grid container direction='row' justify='flex-start'>
                            <Typography variant='h4' className={classes.social}>socials</Typography>

                        </Grid>

                        <Grid container direction='row' className={classes.socialIcons}>


                            <Grid className={classes.icon}>
                                <a href='https://www.instagram.com/flw.cc/'>
                                    <img src={ig} alt='ig' />
                                </a>
                            </Grid>

                            <Grid className={classes.icon}>
                                <a href='https://www.facebook.com/flwcollective'>
                                    <img src={fb} alt='facebook' />
                                </a>
                            </Grid>
                        </Grid>
                        <Grid container direction='row' style={{paddingTop: '1rem'}}>
                            <Button onClick={(e)=>goToAbout()} variant='contained' size='small' className={classes.careerButton}>
                                <Typography variant='h6' className={classes.buttonText}>view career opportunities</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item md={3}>
                <Grid container direction='column' justify='center' alignContent='center' style={{height:'100%'}}>
                <ButtonBase onClick={handleGoSite} >
                    <Typography variant='body2'> Powered by Digital Dash </Typography>
                </ButtonBase>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>

    )
}