import { Button, Grid, Input, makeStyles, Slider } from '@material-ui/core';
import emailjs from 'emailjs-com';
import React, { ChangeEvent, useState } from 'react';
import MinimalTextField from '../MinimalTextField';
import { Info } from './Info'


const useStyles = makeStyles((theme) => ({

    budgetSlider: {
        color: 'white',
        width: '90%',

    },

    budgetInput: {
        borderBottom:`2px solid ${theme.palette.secondary.main}`,
        borderLeft:`2px solid ${theme.palette.secondary.main}`,
        padding: '1.25rem 0.75rem',
        paddingLeft: '2.5em',
        paddingRight: '2.5em',
        fontSize: '1.25rem',
        fontWeight: 600,
        width: '100%'
    },

    sliderClass: {
        border: `2px solid ${theme.palette.secondary.main}`,
        paddingLeft: '3rem',
        paddingTop: '1rem',
    }


}));

const mark = [
    {
        value: 1000,
        label: '1K'
    },

    {
        value: 20000,
        label: '>20k'
    }
]

export default function ContactPageForm() {

    const classes = useStyles();

    //Needed for emails to be sent-> could be moved to seperate component    
    const EMAIL_USER_ID = "user_z9IYOeihnEjYeEQmKv0vG"

    //Needed for emails to be sent-> could be moved to seperate component
    emailjs.init(EMAIL_USER_ID);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [sliderValue, setSliderValue] = React.useState<number | string | Array<number | string>>(1000);
    const [role, setRole] = useState('');
    const [organization, setOrganization] = useState('');
    const [industry, setIndustry] = useState('');
    const [infoText, setInfoText] = useState("")
    const [alertState, setAlertState] = useState(false)

    const handleChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setFullName(e.target.value);
    }
    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setEmail(e.target.value);
    }
    const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setPhone(e.target.value);
    }
    const handleChangeServiceType = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setServiceType(e.target.value);
    }

    const handleChangeRole = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setRole(e.target.value);
    }
    const handleChangeIndustry= (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setIndustry(e.target.value);
    }
    const handleChangeOrganization = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setOrganization(e.target.value);
    }
   

    const handleSliderChange = (e: ChangeEvent<{}>, value: number | number[]) => {
        setAlertState(false)
        setSliderValue(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setSliderValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleFormSubmit = () => {
        
        //Needed for emails to be sent-> could be moved to seperate component    
        const service_ID = "service_atky97i"
        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //Needed for emails to be sent-> could be moved to seperate component    

        if (!fullName || !email || !phone || !sliderValue || !serviceType) {
            setAlertState(true)
            setInfoText("emptyField")
        }
        else if (!emailFormat.test(String(email).toLowerCase())) {
            setAlertState(true)
            setInfoText("invalidEmail")
        }
        else {
            emailjs.send(service_ID, "template_32f120u", {
                from_name: fullName,
                to_name: "flww.cc",
                message: `Phone: ${phone}, Role: ${role}, Industry: ${industry}, Org: ${organization}, Service: ${serviceType}, Budget: ${sliderValue}`,
                reply_to: email,
            }, EMAIL_USER_ID).then(() => {
                setInfoText("success")
                setAlertState(true)
                setTimeout(() => {
                    setAlertState(false)
                }, 5000);
            })
        }
    }



    const handleBlur = () => {
        if (sliderValue < 1000) {
            setSliderValue(1000);
        }
    };


    return (
        <Grid container direction='column'>
            <Info text={infoText} state={alertState} />
            <div style={{ backgroundColor: 'white', padding: '1rem' }}>
                            <Grid container direction='row' style={{ marginBottom: '1rem' }}>
                                <Grid item md={6} xs={12} style={{ paddingRight: '1rem' }}>
                                    <MinimalTextField onChange={handleChangeFullName} placeholder={'your name*'} color='primary' />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <MinimalTextField onChange={handleChangeRole} placeholder={'role'} color='primary' />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' style={{ marginBottom: '1rem' }}>
                                <Grid item md={7} xs={12} style={{ paddingRight: '1rem' }}>
                                    <MinimalTextField onChange={handleChangeEmail} placeholder={'email*'} color='primary' />
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <MinimalTextField onChange={handleChangePhone} placeholder={'phone*'} color='primary' />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' style={{ marginBottom: '1rem' }}>
                                <Grid item md={5} xs={12} style={{ paddingRight: '1rem' }}>
                                    <MinimalTextField onChange={handleChangeOrganization} placeholder={'organization'} color='primary' />
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <MinimalTextField onChange={handleChangeIndustry} placeholder={'industry'} color='primary' />
                                </Grid>
                            </Grid>
                        </div>
            <Grid container direction='row'>
                <MinimalTextField onChange={handleChangeServiceType} type='textarea' multiline={true} placeholder={'product or service we can help you with?'} value={serviceType} />
            </Grid>
            <Grid container direction='row'>
                <Grid item md={2} xs={12}>
                    <Input
                        className={classes.budgetInput}
                        value={sliderValue===1000?null:sliderValue}
                        placeholder={'budget*'}
                        margin="dense"
                        onChange={handleInputChange}
                        style={{width: "250px"}}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 100,
                            min: 1000,
                            max: 20000,
                            type: 'number',
                            'aria-labelledby': 'discrete-slider-always',
                            placeholder: 'Budget*'
                        }}
                    />
                </Grid>
                <Grid item md={10} xs={12} className={classes.sliderClass}>
                    <Slider
                        className={classes.budgetSlider}
                        value={typeof sliderValue === 'number' ? sliderValue : 1000}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={1000}
                        max={20000}
                        marks={mark}
                    />
                </Grid>
            </Grid>
            <Grid container direction='row' justify='flex-end'>
                <Button variant='contained' style={{ borderRadius: 0, padding: '1rem 4rem', textTransform: "none" }} onClick={(e) => handleFormSubmit()}>submit</Button>
            </Grid>
        </Grid>
    )
}

