export default {
    typography: {
        fontFamily: "Avenir Next, -apple-system, BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
        fontSize: 16,
        htmlFontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,

        h1: {
            fontSize: '6rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '2rem'
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 700,
        },
        h5: {
            fontSize: '1.25rem'
        },
        body1: {
            fontSize: '1.5rem',
            fontWeight: 200,
        },
        body2: {
            fontSize: '1rem',
            fontWeight: 400,
        },
    },
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#FFFFFF'
        },
        background: {
            default: '#000000',
        },
        text: {
            primary: '#FFFFFF'
        }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiTextField: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#939393',
                fontWeight: 300,
            },
        },
    },
    shape: {
        borderRadius: 0,
    },
    spacing: (factor:number) => `${factor}rem`
}