import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import budweiser from '../../assets/images/client_logos/BEVERAGES/budweiser.png'
import perpng from '../../assets/images/client_logos/BEVERAGES/perpng.png'
import schweppespng from '../../assets/images/client_logos/BEVERAGES/schweppespng.png'
import triuspng from '../../assets/images/client_logos/BEVERAGES/triuspng.png'
import dji from '../../assets/images/client_logos/LIFESTYLE/djipng.png'
import foot from '../../assets/images/client_logos/LIFESTYLE/footpng.png'
import ink from '../../assets/images/client_logos/LIFESTYLE/inkpng.png'
import jump from '../../assets/images/client_logos/LIFESTYLE/jumppng.png'
import kin from '../../assets/images/client_logos/LIFESTYLE/kinpng.png'
import nike from '../../assets/images/client_logos/LIFESTYLE/nikepng.png'
import pool from '../../assets/images/client_logos/LIFESTYLE/poolpng.png'
import friend from '../../assets/images/client_logos/MUSIC/2friendpng.png'
import ship from '../../assets/images/client_logos/MUSIC/Bass Ship-Logo.png'
import dreams from '../../assets/images/client_logos/MUSIC/dreamspng.png'
import gud from '../../assets/images/client_logos/MUSIC/gudpng.png'
import ultra from '../../assets/images/client_logos/MUSIC/ultrapng.png'
import ac from '../../assets/images/client_logos/SMOKE/7ac.png'
import logo from '../../assets/images/client_logos/SMOKE/logo2.png'
import vuse from '../../assets/images/client_logos/SMOKE/vusepng.png'
import Carousel from 'react-material-ui-carousel'

const client_array = [
    [budweiser, nike, schweppespng, logo, pool, foot, ink, jump],
    [vuse, kin, perpng, friend, ultra,dji, dreams, gud],
    [ac, triuspng, ship,budweiser,foot,dreams,kin,logo]
];


const useStyles = makeStyles((theme) => ({
    workGridItems: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    carousel: {
        width: '100%',
    },
    row: {
        display: 'flex'
    },
    column: {
        flex: '33.33%',
        padding: '5px'
    }
}));
export const ClientSlider = (props: any) => {
    const classes = useStyles()

    return (
        <Carousel navButtonsAlwaysInvisible={true} indicators={false} className={classes.carousel}>

            {
                client_array.map((slide, key) => {
                    return (
                        <Grid container direction='row' justify='space-between' spacing={2}>
                            {
                                slide.map((img, key) => {
                                    return (
                                        <Grid item md={3} xs={6} className={classes.workGridItems} key={key}>
                                            <img src={img} alt='' style={{ margin: 'auto', width: '100%', height: 'auto', maxHeight: '100%' }} />
                                        </Grid>

                                    )
                                })
                            }
                        </Grid>

                    )

                })



            }

        </Carousel>
    )
}
