import { ButtonBase, Grid, makeStyles, Typography, Container } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ContactPageForm from '../shared/Forms/ContactPageForm';
import ImageOverlay from  '../shared/ImageOverlays/ImageOverlay'



const useStyles = makeStyles(() => ({
    workGridItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    gradientUnderline: {
        borderBottom: "3px solid",
        paddingBottom: '0.25rem',
        borderImage: "linear-gradient(90deg, #531EED 0%, #2DCCFE 45.31%, #FF85FF 100%) 10",

    },

    workImages: {
        height: '28rem',
    },

}))
const ContactPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const goToWork = () => {
        history.push('/work')
    }
    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <Container disableGutters={false} maxWidth='lg'>
            <Grid container direction='column' >
                <Grid container direction='row' style={{ marginTop:'8rem' ,marginBottom: '6rem' }}>
                    <Typography variant='h1'>tell us more about your project</Typography>
                </Grid>
                <Grid container direction='row' style={{ marginBottom: '8rem' }}>
                   <ContactPageForm/>
                   </Grid>
                <Grid container direction='row' justify='center' style={{marginBottom:'3.5rem'}}>
                    <Typography variant='h2' style={{fontWeight:700}}>
                        trust our results
                    </Typography>
                </Grid>
                <ImageOverlay/>
                <Grid container direction='row' justify='center' style={{ marginTop: '3rem', marginBottom:'5rem' }}>
                    <ButtonBase onClick={(e) => {goToWork()}}><Typography variant='h3' className={classes.gradientUnderline}>view case studies</Typography></ButtonBase>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ContactPage