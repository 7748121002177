import { ButtonBase, Grid, makeStyles, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    homeIcon: {
        borderRadius: '50%',
        border: '0.2rem solid white',
    },
    navOptions: {
        margin:'0 2rem'
    }
}))
const TopBar = () => {
    let classes = useStyles();
    let history = useHistory();

    const handleGoHome = (e: SyntheticEvent) => {
        history.push('/')
    }
    const handleGoWork = (e: SyntheticEvent) => {
        history.push('/work')
    }
    const handleGoAbout = (e: SyntheticEvent) => {
        history.push('/about')
    }
    const handleGoContact = (e: SyntheticEvent) => {
        history.push('/contact')
    }

    return (
        <Grid container direction='row' justify='space-between' style={{ zIndex: 2000, position: 'relative', paddingTop: '3rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
            <Grid item>
                <Grid container direction='column' alignItems='center' justify='center' style={{ height: "100%" }}>
                    <ButtonBase onClick={handleGoHome} className={classes.homeIcon}>
                        <HomeIcon />
                    </ButtonBase>

                </Grid>
            </Grid>
            <Grid item>
                <ButtonBase onClick={handleGoWork} className={classes.navOptions}>
                    <Typography variant='h5'> work </Typography>
                </ButtonBase>
                <ButtonBase onClick={handleGoAbout} className={classes.navOptions}>
                    <Typography variant='h5'> about </Typography>
                </ButtonBase>
                <ButtonBase onClick={handleGoContact} className={classes.navOptions} style={{marginRight:'0'}}>
                    <Typography variant='h5'> contact </Typography>
                </ButtonBase>
            </Grid>
        </Grid>
    )
}

export default TopBar