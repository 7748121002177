import { Container, createMuiTheme, CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import './App.css';
import defaultTheme from './defaultTheme';
import TopBar from './Components/TopBar/TopBar'
import HomePage from './Components/Pages/HomePage'
import WorkPage from './Components/Pages/WorkPage'
import ContactPage from './Components/Pages/ContactPage'
import AboutPage from './Components/Pages/AboutPage'
import Case_01 from './Components/Pages/CaseStudies/Case_01';
import Case_02 from './Components/Pages/CaseStudies/Case_02';
import Case_03 from './Components/Pages/CaseStudies/Case_03'
import Footer from './Components/shared/Footer';
import fire from './fire';
const useStyles = makeStyles((theme) => ({
  baseContainer: {}
}))

function App() {
  const theme = createMuiTheme(defaultTheme);
  const [bgColor, setBgColor] = useState('black')
  
  const handleBackgroundChange = (color: string) => {
    setBgColor(color)
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Container disableGutters={false} maxWidth='xl' style={{position:'relative',  backgroundColor:bgColor, transition: 'background-color 1000ms ease 0ms'}} className='app'>
          <Router>
            <TopBar />
            <Switch>
              <Route exact path='/' render={() => {
                return (
                  <HomePage changeBG={handleBackgroundChange} />
                )
              }} />
              <Route path='/about' component={AboutPage} />
              <Route path='/contact' component={ContactPage} />
              <Route exact path='/work'  render={() => {
                return (
                  <WorkPage changeBG={handleBackgroundChange} />
                )
              }} />
              <Route path='/work/case_01' component={Case_01}/>
              <Route path='/work/case_02' component={Case_02}/>
              <Route path='/work/case_03' component={Case_03}/>

            </Switch>
            <Footer/>
          </Router>


        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
