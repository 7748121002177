import { Button, Grid, Input, makeStyles, Slider } from '@material-ui/core';
import emailjs from 'emailjs-com';
import React, { ChangeEvent, useState } from 'react';
import MinimalTextField from '../MinimalTextField';
import { Info } from './Info';


const useStyles = makeStyles((theme) => ({

    budgetSlider: {
        color: 'white',
        width: '90%',

    },

    budgetInput: {
        border: `2px solid ${theme.palette.secondary.main}`,
        padding: '1.25rem 0.75rem',
        paddingLeft: '2.5em',
        paddingRight: '2.5em',
        fontSize: '1.25rem',
        fontWeight: 600,
        width: '100%'
    },

    sliderClass: {
        border: `2px solid ${theme.palette.secondary.main}`,
        paddingLeft: '3rem',
        paddingTop: '1rem',
    }


}));

const mark = [
    {
        value: 1000,
        label: '1K'
    },

    {
        value: 20000,
        label: '>20k'
    }
]

const AboutPageForm = () => {

    const classes = useStyles();

    //Needed for emails to be sent-> could be moved to seperate component    
    const EMAIL_USER_ID = "user_z9IYOeihnEjYeEQmKv0vG"

    //Needed for emails to be sent-> could be moved to seperate component
    emailjs.init(EMAIL_USER_ID);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [prevCompany, setPrevCompany] = useState('');
    const [role, setRole] = useState('');
    const [infoText, setInfoText] = useState("")
    const [alertState, setAlertState] = useState(false)

    const handleChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setFullName(e.target.value);
    }
    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setEmail(e.target.value);
    }
    const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setPhone(e.target.value);
    }
    const handleChangePrevCompany = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setPrevCompany(e.target.value);
    } 
    
    const handleChangeRole = (e: ChangeEvent<HTMLInputElement>) => {
        setAlertState(false)
        setRole(e.target.value);
    }
   



    const handleFormSubmit = () => {
        //Needed for emails to be sent-> could be moved to seperate component    
        const service_ID = "service_atky97i"
        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //Needed for emails to be sent-> could be moved to seperate component    



        if (!fullName || !email || !phone || !role) {
            setAlertState(true)
            setInfoText("emptyField")
        }
        else if (!emailFormat.test(String(email).toLowerCase())) {
            setAlertState(true)
            setInfoText("invalidEmail")
        }
        else {
            emailjs.send(service_ID, "template_32f120u", {
                from_name: fullName,
                to_name: "flww.cc",
                message: `Phone: ${phone}, previous company: ${prevCompany}, Type of role: ${role}`,
                reply_to: email,
            }, EMAIL_USER_ID).then(() => {
                setInfoText("success")
                setAlertState(true)
                setTimeout(() => {
                    setAlertState(false)
                }, 5000);
            })
        }
    }



    return (
        <Grid container direction='column'>
            <Info text={infoText} state={alertState} />
            <Grid container direction='row'>
                <MinimalTextField onChange={handleChangeFullName} placeholder={'full name*'} value={fullName} />
            </Grid>
            <Grid container direction='row'>
                <Grid item md={6} xs={12}>
                    <MinimalTextField onChange={handleChangeEmail} placeholder={'email'} value={email} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <MinimalTextField onChange={handleChangePhone} placeholder={'phone'} value={phone} />
                </Grid>
            </Grid>
            <Grid container direction='row'>
                <MinimalTextField onChange={handleChangePrevCompany} type='textarea' multiline={true} placeholder={'previous company'} value={prevCompany} />
            </Grid>
            <Grid container direction='row'>
                <MinimalTextField onChange={handleChangeRole} type='textarea' multiline={true} placeholder={'area of interest/ type of role you are looking for'} value={role} />
            </Grid>
            <Grid container direction='row' justify='flex-end'>
                <Button variant='contained' style={{ borderRadius: 0, padding: '1rem 4rem', textTransform: "none" }} onClick={(e) => handleFormSubmit()}>submit</Button>
            </Grid>
        </Grid>
    )
}


export default AboutPageForm;