import { Avatar, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import WorkImageOverlay from '../shared/ImageOverlays/WorkImageOverlay'


const useStyles = makeStyles(() => ({
    avatarGrid: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '1rem'
    },
    avatar: {
        height: '3rem',
        width: "3rem",
        marginRight: '1rem',
        color: 'white',
    },
    avatarSideText: {
        lineHeight: '3rem'
    },
    
}))

type WorkPageProps = {
    changeBG: Function
}

const WorkPage = ({changeBG}: WorkPageProps) => {
    const classes = useStyles()
    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <Container disableGutters={false} maxWidth='lg' style={{ paddingBottom:"6rem"}}>
            <Grid container direction='column'>
                <Grid container direction='row' justify='center' style={{ marginBottom: '2rem' }}>
                    <Typography variant='h1' color='textPrimary'> work</Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{marginBottom: '5rem'}}>
                    <Grid item className={classes.avatarGrid}>
                        <Avatar className={classes.avatar} style={{backgroundColor: '#501EE9'}}>V</Avatar>
                        <Typography className={classes.avatarSideText} variant='h4'>Videography</Typography>
                    </Grid>
                    <Grid item className={classes.avatarGrid}>
                        <Avatar className={classes.avatar} style={{backgroundColor: '#2DCBFE'}}>P</Avatar>
                        <Typography className={classes.avatarSideText} variant='h4'>Photography</Typography>
                    </Grid>
                    <Grid item className={classes.avatarGrid}>
                        <Avatar className={classes.avatar} style={{backgroundColor: '#FE2D2D'}}>3D</Avatar>
                        <Typography className={classes.avatarSideText} variant='h4'>2D/3D Motion Design</Typography>
                    </Grid>
                    <Grid item className={classes.avatarGrid}>
                        <Avatar className={classes.avatar} style={{backgroundColor: '#FEAA2D'}}>VFX</Avatar>
                        <Typography className={classes.avatarSideText} variant='h4'>Visual FX</Typography>
                    </Grid>
                </Grid>
                <WorkImageOverlay/>
            </Grid>
    </Container>
    )
}

export default WorkPage