import {
    ButtonBase,
    Grid,
    makeStyles,
    Typography,
  } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import case_01 from "../../../assets/images/case_studies/case_01/case_01_cover.jpg";
import case_02 from "../../../assets/images/case_studies/case_02/cover_jordan.jpg";
import case_03 from "../../../assets/images/case_studies/case_03/cover.png";



const useStyles = makeStyles((theme) =>({
    workImages: {
        height: "28rem",
      },
      overlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        transition: "opacity 0.25s ease-in-out",
      },
      workGridItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
   
}))



export default function WorkImageOverlay() {

    const classes = useStyles();
    const history = useHistory();
    const [workItems, setWorkItems] = useState([
    {
      image: case_01,
      link: "work/case_01",
      color: "#281438",
      title: "Devour You",
      showOverlay: false,
      description:
        "An experimental art piece showcasing our approach to visual effects, and more.",
    },
    {
      image: case_02,
      link: "work/case_02",
      color: "blue",
      title: "Jordan",
      showOverlay: false,
      description:
        "Teaser video created for Nike-Jordan brand for their upcoming activation.",
    },
    {
      image: case_03,
      link: "/work/case_03",
      color: "green",
      title: "Kinjaz",
      showOverlay: false,
      description:
        "Project created for one of the most influential dance crews in the world.",
    },
    ]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        console.log(workItems);
      }, [workItems]);

      const goToPage = (link: string) => {
        history.push(link);
      };
      const showOverlay = (index: number) => {
        let temp = [...workItems];
        temp[index].showOverlay = true;
        setWorkItems(temp);
      };
      const hideOverlay = (index: number) => {
        let temp = [...workItems];
        temp[index].showOverlay = false;
        setWorkItems(temp);
      };

    return(
        <Grid container direction='row' justify='center' spacing={4}>
            {workItems.map((work, idx) => {
                return (
                <Grid key={idx} item md={6} className={classes.workGridItems}>
                    {/*<ButtonBase onClick={(e) => { changeBG('black'); goToPage(work.link) }} onMouseEnter={(e) => { changeBG(work.color) }} onMouseLeave={(e) => { changeBG('black') }} style={{ width: '20rem', height: '28rem' }}>*/}
                    <ButtonBase
                    onClick={(e) => {
                        goToPage(work.link);
                    }}
                    onMouseEnter={(e) => {
                        showOverlay(idx);
                    }}
                    onMouseLeave={(e) => {
                        hideOverlay(idx);
                    }}
                    style={{
                        position: "relative",
                        width: "20rem",
                        height: "28rem",
                    }}
                    >
                    <img
                        className={classes.workImages}
                        src={work.image}
                        alt=""
                        style={{ opacity: `${work.showOverlay ? 0 : 1}` }}
                    />
                    <div
                        className={classes.overlay}
                        style={{
                        opacity: `${work.showOverlay ? 1 : 0}`,
                        background:
                            " linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(74,74,180,0.7) 50%, rgba(171,0,255,0.5) 100%)",
                        backgroundSize: "cover",
                        backdropFilter: "blur(40px)",
                        }}
                    >
                        <Grid container direction="column" style={{padding:'3rem',}}>
                        <Grid container direction="row" style={{paddingBottom:'2rem'}}>
                            <Typography variant="h3" align="left">{work.title}</Typography>
                        </Grid>
                        <Grid container direction = "row">
                            <Typography variant="body1" align="left">{work.description}</Typography>
                        </Grid>
                        </Grid>


                    </div>
                    </ButtonBase>
                </Grid>
                );
            })}
          </Grid>
        

    )}