import { Button, Grid, makeStyles, Typography, Container } from '@material-ui/core';
import React from 'react';
import logo from '../../assets/images/aboutus_images/flwcc_logo.svg'
import ImageA from '../../assets/images/image-a.jpg';
import MinimalTextField from '../shared/MinimalTextField';
import AboutPageForm from '../shared/Forms/AboutPageForm';
const useStyles = makeStyles(() => ({
    logo: {
        width: '13.5rem',
        height: 'auto'
    },
    workGridItems: {

    },
    workImages: {
        height: '28rem',
        width: '20rem'
    },
}))
const AboutPage = () => {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    return (
        <Container disableGutters={false} maxWidth= 'md'>
            <Grid container direction='column' >
                <Grid container direction='row' justify='center' style={{marginTop: '7rem'}}>
                    <img src={logo} className={classes.logo} alt=''/>
                </Grid>
                {/*
                <Grid container direction='row' justify='space-between' style={{marginTop: '3rem'}}>

                    <div>
                        <img className={classes.workImages} src={ImageA} alt=''/>
                        <Typography variant='h4'>Max Mao</Typography>
                        <Typography variant='h4'>Co-Founder</Typography>
                    </div>


                    <div>
                        <img className={classes.workImages} src={ImageA} alt=''/>
                        <Typography variant='h4'>Saxon Lane</Typography>
                        <Typography variant='h4'>Co-Founder</Typography>
                    </div>

                </Grid>*/}
                <Grid container direction='row' justify='center' style={{marginTop:'7rem'}}>
                    <Typography variant='h2'>what we're about!</Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{marginTop:'3rem'}}>
                    <Typography variant='body1' align = 'justify' style={{ fontWeight: 200 }}>
                    We specialize in content strategies and creations, providing digital solutions to connect brands to a younger demographic. 
                    Our approach to creating visual content relies on our uniqueness and originality.
                    </Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{marginTop:'7rem'}}>
                    <Typography variant='h2'>what makes us unique?</Typography>
                </Grid>
                <Grid container direction='row' justify='center' style={{marginTop:'3rem'}}>
                    <Typography variant='body1' align = 'justify' style={{ fontWeight: 200 }}>
                    We have the ability to integrate and tailor our services--photos, videos, visual effects and 3D motion design-- into your brand.
                    </Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' style={{marginTop:'7rem', marginBottom:'1rem'}}>
                    <Typography variant='h2'>join our team!</Typography>
                </Grid>
                <Grid container direction='row' style={{ marginTop: '0.5rem' ,marginBottom: '8rem' }}>
                  <AboutPageForm/>
                </Grid>
                <Grid container direction='row' justify='center' style={{marginTop:'2rem', marginBottom:'8rem'}}>
                    <Typography variant='h2'>Keep it flowing.</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AboutPage