import {
  Avatar,
  ButtonBase,
  Grid,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import HomePageForm from "../shared/Forms/HomePageForm";
import ImageOverlay from  '../shared/ImageOverlays/ImageOverlay'


import background_animation from "../../assets/home_animation.mp4";
import { ClientSlider } from "../Carousels/ClientSlider";

const useStyles = makeStyles((theme) => ({
  base: {
    width: "100%",
    marginTop: "50rem",
  },
  particleBack: {
    width: "100%",
    height: "auto",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    marginLeft: "auto",
    marginRight: "auto",
  },
  avatarGrid: {
    display: "flex",
    flexDirection: "row",
    marginRight: "1rem",
  },
  avatar: {
    height: "3rem",
    width: "3rem",
    marginRight: "1rem",
    color: "white",
  },
  avatarSideText: {
    lineHeight: "3rem",
  },
  workGridItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  workImages: {
    height: "28rem",
  },
  gradientUnderline: {
    borderBottom: "3px solid",
    paddingBottom: "0.25rem",
    borderImage:
      "linear-gradient(90deg, #531EED 0%, #2DCCFE 45.31%, #FF85FF 100%) 10",
    borderTop: '0px',
    borderLeft: '0px',
    borderRight: '0px'
  },
  clientImgBox: {
    height: "15.625rem",
    width: "15.625rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    transition: "opacity 0.15s ease-in-out",
  },
  projectTitle: {},
}));

type HomePageProps = {
  changeBG: Function;
};

const HomePage = ({ changeBG }: HomePageProps) => {
  const classes = useStyles();
  const history = useHistory();

  const goToWork = () => {
    history.push("/work");
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <span>
      <video loop autoPlay={true} className={classes.particleBack} muted>
        <source src={background_animation} type="video/mp4" />
      </video>
      <Container disableGutters={false} maxWidth="lg">
        <Grid container direction="column" className={classes.base}>
          <Typography
            variant={"h2"}
            align="center"
            style={{ margin: "1rem 0" }}
          >
            Recent Work
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            style={{ margin: "2rem 0" }}
          >
            <Grid item className={classes.avatarGrid}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: "#501EE9" }}
              >
                V
              </Avatar>
              <Typography className={classes.avatarSideText} variant="h4">
                Videography
              </Typography>
            </Grid>
            <Grid item className={classes.avatarGrid}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: "#2DCBFE" }}
              >
                P
              </Avatar>
              <Typography className={classes.avatarSideText} variant="h4">
                Photography
              </Typography>
            </Grid>
            <Grid item className={classes.avatarGrid}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: "#FE2D2D" }}
              >
                3D
              </Avatar>
              <Typography className={classes.avatarSideText} variant="h4">
                2D/3D Motion Design
              </Typography>
            </Grid>
            <Grid item className={classes.avatarGrid}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: "#FEAA2D" }}
              >
                VFX
              </Avatar>
              <Typography className={classes.avatarSideText} variant="h4">
                Visual FX
              </Typography>
            </Grid>
          </Grid>
          
          <ImageOverlay/>
            
          <Grid
            container
            direction="row"
            justify="center"
            style={{ marginTop: "3rem" }}
          >
            <ButtonBase
              onClick={(e) => {
                goToWork();
              }}
            >
              <Typography variant="h3" className={classes.gradientUnderline}>
                see all work
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            style={{ margin: "8rem 0" }}
          >
            <Typography variant="h2"> Clients </Typography>
          </Grid>
          <Grid container direction="row" style={{ marginTop: "1em", padding:'0 4rem' }}>
            <ClientSlider />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            style={{ marginTop: "8rem", marginBottom: "6rem" }}
          >
            <Typography variant="h2">Excited? Lets Talk</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            style={{ marginBottom: "5rem" }}
            justify="center"
          >
            <Grid item xl={12} md={11}>
              <HomePageForm />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </span>
  );
};

export default HomePage;
